import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import './Projects.scss'

const EUCompliance4UA = () => {
  const intl = useIntl()

  return (
    <div className="eu-compliance">
      {/* Header Section */}
      <div className="eu-compliance__header">
        <h1 className="eu-compliance__title">EUCompliance4UA</h1>
        <h2 className="eu-compliance__subtitle">
          <FormattedMessage id="euCompliance.subtitle" />
        </h2>
        <h3 className="eu-compliance__subtitle-small">
          <FormattedMessage id="euCompliance.subtitleSmall" />
        </h3>

        <div className="eu-compliance__calls">
          <p>
            <FormattedMessage id="euCompliance.calls.first" />
          </p>
          <p>
            <FormattedMessage id="euCompliance.calls.second" />
          </p>
        </div>
      </div>

      {/* Project Logo */}
      <div className="eu-compliance__logo">
        <img
          src="./img/EUComplianceM4UA.png"
          alt={intl.formatMessage({ id: 'euCompliance.logo.alt' })}
        />
      </div>

      {/* Basic Project Info */}
      <section className="eu-compliance__section">
        <h2 className="eu-compliance__section-title">
          <FormattedMessage id="euCompliance.projectTitle" />
        </h2>
        <div className="eu-compliance__info-block">
          <p>
            <strong>
              <FormattedMessage id="euCompliance.projectNumber.label" />:
            </strong>{' '}
            101179706
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.shortName.label" />:
            </strong>{' '}
            EUComplianceM4UA
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.projectType.label" />:
            </strong>{' '}
            ERASMUS-EDU-2024-CBHE-STRAND-1 ERASMUS-LS
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.priority.label" />:
            </strong>{' '}
            <FormattedMessage id="euCompliance.priority.value" />
          </p>
          <ul className="eu-compliance__list">
            <li>
              <FormattedMessage id="euCompliance.priorityList.item1" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.priorityList.item2" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.priorityList.item3" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.priorityList.item4" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.priorityList.item5" />
            </li>
          </ul>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.duration.label" />:
            </strong>{' '}
            <FormattedMessage id="euCompliance.duration.value" />
          </p>
        </div>
      </section>

      {/* Funding Info */}
      <section className="eu-compliance__section">
        <h3 className="eu-compliance__section-title">
          <FormattedMessage id="euCompliance.funding.title" />
        </h3>
        <div className="eu-compliance__info-block">
          <p>
            <FormattedMessage id="euCompliance.funding.program" />
          </p>
          <p>
            <FormattedMessage id="euCompliance.funding.instrument" />
          </p>
          <p>
            <FormattedMessage id="euCompliance.funding.agreement" />
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.funding.amount.label" />:
            </strong>{' '}
            <FormattedMessage id="euCompliance.funding.amount.value" />
          </p>
        </div>
      </section>

      {/* Target Groups */}
      <section className="eu-compliance__section">
        <h3 className="eu-compliance__section-title">
          <FormattedMessage id="euCompliance.targetGroups.title" />:
        </h3>
        <ol className="eu-compliance__list eu-compliance__list--numbered">
          <li>
            <strong>
              <FormattedMessage id="euCompliance.targetGroups.group1.title" />.
            </strong>{' '}
            <FormattedMessage id="euCompliance.targetGroups.group1.description" />
          </li>
          <li>
            <strong>
              <FormattedMessage id="euCompliance.targetGroups.group2.title" />.
            </strong>{' '}
            <FormattedMessage id="euCompliance.targetGroups.group2.description" />
          </li>
          <li>
            <strong>
              <FormattedMessage id="euCompliance.targetGroups.group3.title" />
            </strong>{' '}
            <FormattedMessage id="euCompliance.targetGroups.group3.description" />
          </li>
        </ol>
      </section>

      {/* Grant Recipient */}
      <section className="eu-compliance__section">
        <h3 className="eu-compliance__section-title">
          <FormattedMessage id="euCompliance.grantRecipient.title" />:
        </h3>
        <div className="eu-compliance__info-block">
          <p>
            <strong>
              <FormattedMessage id="euCompliance.name.label" />:
            </strong>{' '}
            <FormattedMessage id="euCompliance.grantRecipient.name" />
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.country.label" />:
            </strong>{' '}
            <FormattedMessage id="euCompliance.grantRecipient.country" />
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.website.label" />:
            </strong>{' '}
            <a
              href="https://www.hs-wismar.de/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.hs-wismar.de/
            </a>
          </p>

          <div className="eu-compliance__contact">
            <p>
              <strong>
                <FormattedMessage id="euCompliance.coordinator.label" />:
              </strong>{' '}
              <FormattedMessage id="euCompliance.grantRecipient.coordinator" />
            </p>
            <p>
              <strong>
                <FormattedMessage id="euCompliance.projectManager.label" />:
              </strong>{' '}
              <FormattedMessage id="euCompliance.grantRecipient.projectManager" />
            </p>
            <p>
              <strong>
                <FormattedMessage id="euCompliance.contacts.label" />:
              </strong>
            </p>
            <p>
              <FormattedMessage id="euCompliance.phone.label" />: +038417537472
            </p>
            <p>
              <FormattedMessage id="euCompliance.email.label" />:{' '}
              <a href="mailto:anna.fomenko@hs-wismar.de">
                anna.fomenko@hs-wismar.de
              </a>
            </p>
          </div>
        </div>
      </section>

      {/* Partners Section */}
      <section className="eu-compliance__section">
        <h3 className="eu-compliance__section-title">
          <FormattedMessage id="euCompliance.partnership.title" />:
        </h3>
        <ul className="eu-compliance__list">
          <li>
            <FormattedMessage id="euCompliance.partnership.partner1" />
          </li>
          <li>
            <FormattedMessage id="euCompliance.partnership.partner2" />
          </li>
          <li>
            <FormattedMessage id="euCompliance.partnership.partner3" />
          </li>
        </ul>

        <h3 className="eu-compliance__section-subtitle">
          <FormattedMessage id="euCompliance.partnershipUkraine.title" />:
        </h3>
        <ul className="eu-compliance__list">
          <li>
            <FormattedMessage id="euCompliance.partnershipUkraine.partner1" />
          </li>
          <li>
            <FormattedMessage id="euCompliance.partnershipUkraine.partner2" />
          </li>
          <li>
            <FormattedMessage id="euCompliance.partnershipUkraine.partner3" />
          </li>
          <li>
            <FormattedMessage id="euCompliance.partnershipUkraine.partner4" />
          </li>
          <li>
            <FormattedMessage id="euCompliance.partnershipUkraine.partner5" />
          </li>
        </ul>

        <h3 className="eu-compliance__section-subtitle">
          <FormattedMessage id="euCompliance.associatedPartner.title" />:
        </h3>
        <ul className="eu-compliance__list">
          <li>
            <FormattedMessage id="euCompliance.associatedPartner.partner1" />
          </li>
        </ul>
      </section>

      {/* Project Goals */}
      <section className="eu-compliance__section">
        <h3 className="eu-compliance__section-title">
          <FormattedMessage id="euCompliance.goals.title" />:
        </h3>
        <div className="eu-compliance__info-block">
          <p>
            <FormattedMessage id="euCompliance.goals.description" />
          </p>

          <h4 className="eu-compliance__section-subtitle">
            <FormattedMessage id="euCompliance.tasks.title" />:
          </h4>
          <ol className="eu-compliance__list eu-compliance__list--numbered">
            <li>
              <FormattedMessage id="euCompliance.tasks.task1" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.tasks.task2" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.tasks.task3" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.tasks.task4" />
            </li>
          </ol>
        </div>
      </section>

      {/* Activity Areas */}
      <section className="eu-compliance__section">
        <h3 className="eu-compliance__section-title">
          <FormattedMessage id="euCompliance.activities.title" />:
        </h3>

        <div className="eu-compliance__activity">
          <h4 className="eu-compliance__activity-title">
            <FormattedMessage id="euCompliance.activities.activity1.title" />
          </h4>
          <ul className="eu-compliance__list">
            <li>
              <FormattedMessage id="euCompliance.activities.activity1.item1" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.activities.activity1.item2" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.activities.activity1.item3" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.activities.activity1.item4" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.activities.activity1.item5" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.activities.activity1.item6" />
            </li>
          </ul>
        </div>

        <div className="eu-compliance__activity">
          <h4 className="eu-compliance__activity-title">
            <FormattedMessage id="euCompliance.activities.activity2.title" />
          </h4>
        </div>

        <div className="eu-compliance__activity">
          <h4 className="eu-compliance__activity-title">
            <FormattedMessage id="euCompliance.activities.activity3.title" />
          </h4>
        </div>

        <div className="eu-compliance__activity">
          <h4 className="eu-compliance__activity-title">
            <FormattedMessage id="euCompliance.activities.activity4.title" />:
          </h4>
          <ul className="eu-compliance__list">
            <li>
              <FormattedMessage id="euCompliance.activities.activity4.item1" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.activities.activity4.item2" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.activities.activity4.item3" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.activities.activity4.item4" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.activities.activity4.item5" />
            </li>
          </ul>
        </div>
      </section>

      {/* Expected Results */}
      <section className="eu-compliance__section">
        <h3 className="eu-compliance__section-title">
          <FormattedMessage id="euCompliance.results.title" />:
        </h3>

        <div className="eu-compliance__result">
          <h4 className="eu-compliance__result-title">
            <FormattedMessage id="euCompliance.results.result1.title" />
          </h4>
          <ul className="eu-compliance__list">
            <li>
              <FormattedMessage id="euCompliance.results.result1.item1" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.results.result1.item2" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.results.result1.item3" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.results.result1.item4" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.results.result1.item5" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.results.result1.item6" />
            </li>
          </ul>
        </div>

        <div className="eu-compliance__result">
          <h4 className="eu-compliance__result-title">
            <FormattedMessage id="euCompliance.results.result2.title" />
          </h4>
          <ul className="eu-compliance__list">
            <li>
              <FormattedMessage id="euCompliance.results.result2.item1" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.results.result2.item2" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.results.result2.item3" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.results.result2.item4" />
            </li>
          </ul>
        </div>

        <div className="eu-compliance__result">
          <h4 className="eu-compliance__result-title">
            <FormattedMessage id="euCompliance.results.result3.title" />
          </h4>
          <ul className="eu-compliance__list">
            <li>
              <FormattedMessage id="euCompliance.results.result3.item1" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.results.result3.item2" />
            </li>
          </ul>
        </div>

        <div className="eu-compliance__result">
          <h4 className="eu-compliance__result-title">
            <FormattedMessage id="euCompliance.results.result4.title" />
          </h4>
          <ul className="eu-compliance__list">
            <li>
              <FormattedMessage id="euCompliance.results.result4.item1" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.results.result4.item2" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.results.result4.item3" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.results.result4.item4" />
            </li>
            <li>
              <FormattedMessage id="euCompliance.results.result4.item5" />
            </li>
          </ul>
        </div>
      </section>

      {/* Ukrainian Coordinator */}
      <section className="eu-compliance__section">
        <h3 className="eu-compliance__section-title">
          <FormattedMessage id="euCompliance.ukrainianCoordinator.title" />:
        </h3>
        <div className="eu-compliance__info-block">
          <p>
            <strong>
              <FormattedMessage id="euCompliance.name.label" />:
            </strong>{' '}
            <FormattedMessage id="euCompliance.ukrainianCoordinator.name" />
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.website.label" />:
            </strong>{' '}
            <a
              href="https://kntu.kr.ua/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://kntu.kr.ua/
            </a>
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.phone.label" />:
            </strong>{' '}
            +380522559234
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.responsiblePerson.label" />:
            </strong>{' '}
            <FormattedMessage id="euCompliance.ukrainianCoordinator.responsiblePerson" />
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.contacts.label" />:
            </strong>
          </p>
          <p>
            <FormattedMessage id="euCompliance.phone.label" />: +380509086628
          </p>
          <p>
            <FormattedMessage id="euCompliance.email.label" />:{' '}
            <a href="mailto:nataliia.shalimova@gmail.com">
              nataliia.shalimova@gmail.com
            </a>
          </p>
        </div>
      </section>

      {/* Ukrainian Partners */}
      <section className="eu-compliance__section">
        <h3 className="eu-compliance__section-title">
          <FormattedMessage id="euCompliance.ukrainianPartners.title" />:
        </h3>

        <div className="eu-compliance__partner">
          <p>
            <strong>
              <FormattedMessage id="euCompliance.name.label" />:
            </strong>{' '}
            <FormattedMessage id="euCompliance.ukrainianPartners.partner1.name" />
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.website.label" />:
            </strong>{' '}
            <a
              href="https://dnuvs.ukr.education"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://dnuvs.ukr.education
            </a>
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.phone.label" />:
            </strong>{' '}
            +380968904263
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.responsiblePerson.label" />:
            </strong>{' '}
            <FormattedMessage id="euCompliance.ukrainianPartners.partner1.responsiblePerson" />
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.contacts.label" />:
            </strong>
          </p>
          <p>
            <FormattedMessage id="euCompliance.phone.label" />: +380508697738
          </p>
          <p>
            <FormattedMessage id="euCompliance.email.label" />:{' '}
            <a href="mailto:nazumuch@ukr.net">nazumuch@ukr.net</a>
          </p>
        </div>

        <div className="eu-compliance__partner">
          <p>
            <strong>
              <FormattedMessage id="euCompliance.name.label" />:
            </strong>{' '}
            <FormattedMessage id="euCompliance.ukrainianPartners.partner2.name" />
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.website.label" />:
            </strong>{' '}
            <a
              href="http://idgu.edu.ua/"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://idgu.edu.ua/
            </a>
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.phone.label" />:
            </strong>{' '}
            +380484163001
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.responsiblePerson.label" />:
            </strong>{' '}
            <FormattedMessage id="euCompliance.ukrainianPartners.partner2.responsiblePerson" />
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.contacts.label" />:
            </strong>
          </p>
          <p>
            <FormattedMessage id="euCompliance.phone.label" />: +380962136376
          </p>
          <p>
            <FormattedMessage id="euCompliance.email.label" />:{' '}
            <a href="mailto:vms.idgu@ukr.net">vms.idgu@ukr.net</a>
          </p>
        </div>

        <div className="eu-compliance__partner">
          <p>
            <strong>
              <FormattedMessage id="euCompliance.name.label" />:
            </strong>{' '}
            <FormattedMessage id="euCompliance.ukrainianPartners.partner3.name" />
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.website.label" />:
            </strong>{' '}
            <a
              href="https://www.hneu.edu.ua/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.hneu.edu.ua/
            </a>
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.phone.label" />:
            </strong>{' '}
            +380577021832
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.responsiblePerson.label" />:
            </strong>{' '}
            <FormattedMessage id="euCompliance.ukrainianPartners.partner3.responsiblePerson" />
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.contacts.label" />:
            </strong>
          </p>
          <p>
            <FormattedMessage id="euCompliance.phone.label" />: +380677118725
          </p>
          <p>
            <FormattedMessage id="euCompliance.email.label" />:{' '}
            <a href="mailto:oleksandra.gron@hneu.net">
              oleksandra.gron@hneu.net
            </a>
          </p>
        </div>

        <div className="eu-compliance__partner">
          <p>
            <strong>
              <FormattedMessage id="euCompliance.name.label" />:
            </strong>{' '}
            <FormattedMessage id="euCompliance.ukrainianPartners.partner4.name" />
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.website.label" />:
            </strong>{' '}
            <a
              href="https://karazin.ua/en/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://karazin.ua/en/
            </a>{' '}
            <a
              href="https://uepa.karazin.ua/en/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://uepa.karazin.ua/en/
            </a>
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.phone.label" />:
            </strong>{' '}
            +380997044802
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.responsiblePerson.label" />:
            </strong>{' '}
            <FormattedMessage id="euCompliance.ukrainianPartners.partner4.responsiblePerson" />
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.contacts.label" />:
            </strong>
          </p>
          <p>
            <FormattedMessage id="euCompliance.phone.label" />: +380662509057
          </p>
          <p>
            <FormattedMessage id="euCompliance.email.label" />:{' '}
            <a href="mailto:marynavasylieva@karazin.ua">
              marynavasylieva@karazin.ua
            </a>
          </p>
        </div>
      </section>

      {/* Associated Partner */}
      <section className="eu-compliance__section">
        <h3 className="eu-compliance__section-title">
          <FormattedMessage id="euCompliance.associatedPartnerDetail.title" />:
        </h3>
        <div className="eu-compliance__info-block">
          <p>
            <strong>
              <FormattedMessage id="euCompliance.name.label" />:
            </strong>{' '}
            <FormattedMessage id="euCompliance.associatedPartnerDetail.name" />
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.website.label" />:
            </strong>{' '}
            <a
              href="https://pnrm.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://pnrm.org/
            </a>
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.phone.label" />:
            </strong>{' '}
            +380976964273
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.responsiblePerson.label" />:
            </strong>{' '}
            <FormattedMessage id="euCompliance.associatedPartnerDetail.responsiblePerson" />
          </p>
          <p>
            <strong>
              <FormattedMessage id="euCompliance.contacts.label" />:
            </strong>
          </p>
          <p>
            <FormattedMessage id="euCompliance.phone.label" />: +380976964273
          </p>
          <p>
            <FormattedMessage id="euCompliance.email.label" />:{' '}
            <a href="mailto:viktoriyagura2016@gmail.com">
              viktoriyagura2016@gmail.com
            </a>
          </p>
        </div>
      </section>

      {/* Project Website */}
      <section className="eu-compliance__section eu-compliance__section--highlight">
        <h3 className="eu-compliance__section-title">
          <FormattedMessage id="euCompliance.projectWebsite.title" />:
        </h3>
        <p className="eu-compliance__website-link">
          <a
            href="https://EUComplianceM4UA.eu"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://EUComplianceM4UA.eu
          </a>
        </p>
      </section>
    </div>
  )
}

export default EUCompliance4UA
